import { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { GridItem, GridRow } from '../../components/Grid';
import { ShareButton } from '../../components/ShareButton';
import { DecoratedLine, ShareBlock } from './styles';

import type { GenericSocialShare as TGenericSocialShare } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISocialMediaListComponents } from 'types/SocialSharing';
import { TrackingService } from 'service/tracking/trackingService';

interface IGenericSocialShare extends ISharedTemplateProps {
  templateProps: TGenericSocialShare;
}

export const GenericSocialShare = ({
  templateProps,
  metadata,
  globalMetadata,
}: IGenericSocialShare) => {
  const { socialMediaList, buttonLabel } = templateProps;
  const shareImage = metadata?.images?.[0]?.url || globalMetadata?.image?.url || '';
  return (
    <GridRow columns={16}>
      <GridItem colSpan={2} />
      <GridItem colSpan={12}>
        <ShareBlock data-test="recipe-overview-share-block" data-print="hide">
          <DecoratedLine />

          <ShareButton
            shareImage={shareImage}
            isGeneric
            socialMediaListComponents={socialMediaList as ISocialMediaListComponents[]}
            labels={{ share: buttonLabel }}
            trackingEvents={{
              clickSocialShare: TrackingService.clickSocialShare,
            }}
          />
          <DecoratedLine className="md:ml-5" />
        </ShareBlock>
      </GridItem>
      <GridItem colSpan={2} />
    </GridRow>
  );
};
