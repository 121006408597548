import tw from 'tailwind-styled-components';

export const CenterBlock = tw.div`
  flex
  flex-wrap
  justify-center
`;
export const ShareBlock = tw(CenterBlock)`
  align-center
  items-center
`;

export const DecoratedLine = tw.div`
  h-px
  flex-grow
  bg-brown
  md:mr-5
`;
