/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { ShareSmallIcon } from '@icons/ShareSmallIcon';
import { PlainButton } from '../PlainButton';
import { SocialSharing } from '../SocialSharing';
import { ISocialMediaListComponents } from 'types/SocialSharing';

export interface IShareButtonProps {
  isGeneric?: boolean;
  id?: string;
  shareText?: string;
  shareImage?: string;
  socialMediaListComponents?: ISocialMediaListComponents[];
  labels?: Record<string, string>;
  eventEC?: string;
  trackingEvents?: {
    clickSocialShare: (
      eventName: string,
      eventEC: string,
      socialPlatform: string,
      sharedItemName: string,
    ) => void;
  };
}

export const ShareButton = ({
  isGeneric = false,
  id = 'social-share-button',
  shareText = '',
  shareImage,
  socialMediaListComponents,
  labels,
  eventEC = 'recipe',
  trackingEvents,
}: IShareButtonProps) => {
  const [shareVisible, setShareVisible] = useState<boolean>(false);
  const icon = <ShareSmallIcon />;

  return (
    <div
      className="relative"
      onMouseEnter={() => setShareVisible(true)}
      onMouseLeave={() => setShareVisible(false)}
      onClick={() => setShareVisible(!shareVisible)}
    >
      <PlainButton icon={icon} label={labels?.share} data-test={id} />
      {shareVisible && (
        <div className="absolute bg-white -left-5 md:left-0 pt-1 z-10 overflow-x-hidden">
          <SocialSharing
            isGeneric={isGeneric}
            data-test={`${id}-sharing`}
            socialMediaListComponents={socialMediaListComponents}
            sharedItemName={shareText}
            sharedItemImage={shareImage}
            eventEC={eventEC}
            trackingEvents={
              trackingEvents?.clickSocialShare && {
                clickSocialShare: trackingEvents?.clickSocialShare,
              }
            }
          />
        </div>
      )}
    </div>
  );
};

export default ShareButton;
