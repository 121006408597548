import NextImage from 'components/NextImage';
import * as Socials from 'react-share';
import { Text as Paragraph } from '../Typography';
import { ISocialMediaListComponents } from 'types/SocialSharing';

const SHARE_POS_SOCIAL_SHARE_COMPONENT = 'SocialShareComponent';
const SHARE_POS_PINTEREST_BUTTON = 'PinterestShareButton';

export const SocialSharing = ({
  isGeneric,
  id = 'social-sharing',
  sharedItemName,
  sharedItemImage,
  eventEC = 'recipe',
  eventName = `click_share_${eventEC}`,
  socialMediaListComponents,
  trackingEvents,
}: {
  isGeneric: boolean;
  id?: string;
  sharedItemName: string;
  sharedItemImage: string | undefined;
  eventEC?: string;
  eventName?: string;
  socialMediaListComponents?: ISocialMediaListComponents[];
  trackingEvents?: {
    clickSocialShare: (
      eventName: string,
      eventEC: string,
      socialPlatform: string,
      sharedItemName: string,
    ) => void;
  };
}) => {
  const onClickHandler = (socialPlatform: ISocialMediaListComponents) => {
    if (!isGeneric && trackingEvents?.clickSocialShare) {
      trackingEvents?.clickSocialShare(eventName, eventEC, socialPlatform.name, sharedItemName);
    }
  };
  return (
    <div
      className="rounded-xl border border-[#bbc4ba] w-[12.5rem] bg-white pt-3 pb-3.5 flex flex-col"
      id={id}
    >
      {socialMediaListComponents?.map((socialPlatform) => {
        const displayShareButton = socialPlatform.socialMediaComponentName || sharedItemImage;
        if (
          (socialPlatform?.socialSharePosition?.find(
            (element) => element === SHARE_POS_SOCIAL_SHARE_COMPONENT,
          ) ||
            isGeneric) &&
          displayShareButton &&
          socialPlatform.socialMediaComponentName
        ) {
          const Item: any = Socials[socialPlatform.socialMediaComponentName];
          const returnJsx = (
            <div
              className="py-3 pr-0 pl-[1.1875rem] hover:bg-light-brown flex flex-row items-center cursor-pointer"
              key={socialPlatform.socialMediaComponentName}
            >
              {socialPlatform.assetsComponents?.[0]?.asset?.[0]?.url && (
                <div className="w-7 h-7 mr-4 p-[5px]">
                  <NextImage
                    src={socialPlatform?.assetsComponents?.[0].asset[0].url}
                    mimeType={String(socialPlatform?.assetsComponents?.[0].asset[0]?.mimeType)}
                    alt=""
                    width={18}
                    height={18}
                  />
                </div>
              )}
              <Paragraph tag="div" type="md">
                {socialPlatform.shareMsg}
              </Paragraph>
            </div>
          );
          return (
            <Item
              media={SHARE_POS_PINTEREST_BUTTON && sharedItemImage ? sharedItemImage : undefined}
              url={window.location.href}
              className="m-0"
              key={socialPlatform.socialMediaComponentName}
              onClick={() => onClickHandler(socialPlatform)}
            >
              {returnJsx}
            </Item>
          );
        }
        return null;
      })}
    </div>
  );
};
